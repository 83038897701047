/* eslint-disable jsx-a11y/alt-text */

import Link from 'next/link';
import { Data } from '@/shared/types/internal.types';

/* eslint-disable @next/next/no-img-element */

import ExpirationCounter from '../ExpirationCounter';
import { isExpired } from '@/utils';
import ImageComponent from '../Image';
import { sponsoredCategoryType } from '@/lib/sponsored';

export interface OfferCardType extends Data {
  highlight?: boolean;
  id: number;
  titulo: string;
  uri: string;
  data: string;
  expiracao: string;
  idade: string;
  url: string;
  flags: number;
  preco: {
    tipo: number;
    parcelas: number;
    valor: number;
    inteiro: number;
    decimal: number;
    valorOriginal: number;
  };
  status: number;
  imagens: {
    '300': string;
    '600': string;
  };
  blurhash: string;
  loja: {
    nome: string;
    imagem: string;
    uri: string;
  };
  produto: {
    id: string;
    nome: string;
    uri: string;
    url: string;
  };
  categoriaPatrocinada: sponsoredCategoryType;
}

export const enum OfferFlags {
  FLAG_OFERTA_TOP = 1,
  FLAG_FRETE_GRATIS = 8,
  FLAG_INTERNACIONAL = 16,
  FLAG_CRONOMETRO = 32,
  FLAG_EXCLUSIVO_APP = 256,
}

interface OffersCardProps {
  props: OfferCardType;
  noStore?: boolean;
}

// Função para atualizar o cronômetro

const Image = ({ props }: OffersCardProps) => {
  const shouldDisableNoPrefetch = !(props?.status == 3 || (props?.expiracao && isExpired(props?.expiracao)) || props?.status == 4);

  return (
    <div className="block relative py-4 mb-[-13px] border-b border-gray-200">
      <span className="absolute top-[16px] left-[16px] text-[.6875rem] text-gray-600">{props?.idade}</span>
      <Link className="clique-oferta-listagem" href={`/${props?.uri}/`} target="_self" prefetch={shouldDisableNoPrefetch}>
        <ImageComponent src={props?.imagens['300']} alt={props?.titulo} width={180} height={180} className="block w-[180px] h-[180px] mx-auto" />
      </Link>
    </div>
  );
};

export const Tags = ({ props, noStore = false }: OffersCardProps) => {
  return (
    <div className="relative flex items-center justify-between h-[26px] tags-post w-full">
      {props?.loja && (
        <div className="flex items-center border border-gray-300 h-auto bg-white rounded tag-contador">
          {!noStore && (
            <Link className="no-underline" href={`/${props?.loja?.uri}/`}>
              <ImageComponent src={props?.loja?.imagem} alt={props?.loja?.nome} width={24} height={24} className="rounded-sm block w-[24px] h-[24px]" />
            </Link>
          )}
          {props?.expiracao && props?.flags & OfferFlags.FLAG_CRONOMETRO ? <ExpirationCounter id={props?.id} endDate={props?.expiracao} /> : null}
        </div>
      )}
      <div className="flex items-center flex-grow-1 justify-end tags-adicionais grow">
        {props?.flags & OfferFlags.FLAG_INTERNACIONAL ? (
          <div className="h-[26px] w-[26px] mr-1 bg-white inline-flex border border-gray-300 rounded items-center justify-center tag-internacional text-gray-800">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              focusable="false"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
              data-icon="mdi-airplane"
              style={{ verticalAlign: '-0.125em', transform: 'rotate(45deg)' }}>
              <path
                fill="currentColor"
                d="M20.56 3.91c.59.59.59 1.54 0 2.12l-3.89 3.89l2.12 9.19l-1.41 1.42l-3.88-7.43L9.6 17l.36 2.47l-1.07 1.06l-1.76-3.18l-3.19-1.77L5 14.5l2.5.37L11.37 11L3.94 7.09l1.42-1.41l9.19 2.12l3.89-3.89c.56-.58 1.56-.58 2.12 0Z"
              />
            </svg>
          </div>
        ) : null}
        {props?.flags & OfferFlags.FLAG_OFERTA_TOP ? (
          <div className="h-[26px] border border-gray-300 rounded px-2 text-white text-[0.6875rem] leading-6 font-semibold bg-gray-800 tag-top">TOP</div>
        ) : null}
      </div>
    </div>
  );
};

const DescriptionBody = ({ props }: OffersCardProps) => {
  const shouldDisableNoPrefetch = !(props?.status == 3 || (props?.expiracao && isExpired(props?.expiracao)) || props?.status == 4);

  return (
    <div className="px-4 pb-4 h-[268px] relative">
      <div className="mb-4">
        <Tags props={props} />
      </div>
      <div className="text-gray-900">
        {props?.preco.tipo === 0 && <p className="text-2xl leading-none font-medium">Grátis</p>}
        {props?.preco.tipo === 1 && (
          <>
            <small className="block text-sm font-normal">a partir de</small>
            <p className="text-2xl font-medium leading-4 ">
              R$<span>{props?.preco.inteiro}</span>
              <small className="text-base ml-[2px] align-text-top">{props?.preco.decimal}</small>
            </p>
          </>
        )}
        {props?.preco.tipo === 2 && (
          <>
            <del className="block text-sm font-normal">R${props?.preco.valorOriginal}</del>
            <p className="text-2xl font-medium leading-4 ">
              R$<span>{props?.preco.inteiro}</span>
              <small className="text-base ml-[2px] align-text-top">{props?.preco.decimal}</small>
            </p>
          </>
        )}
        {props?.preco.tipo === 3 && (
          <>
            <p className="text-2xl font-medium leading-4 ">
              R$<span>{props?.preco.inteiro}</span>
              <small className="text-base ml-[2px] align-text-top">{props?.preco.decimal}</small>
            </p>
            <small className="block text-sm font-normal text-gray-700">em {props?.preco.parcelas}x sem juros</small>
          </>
        )}
        {props?.preco.tipo === 4 && (
          <>
            <small className="block text-sm font-normal text-gray-700">em {props?.preco.parcelas}x de</small>
            <p className="text-2xl font-medium leading-4 ">
              R$<span>{props?.preco.inteiro}</span>
              <small className="text-base ml-[2px] align-text-top">{props?.preco.decimal}</small>
            </p>
          </>
        )}
      </div>
      <p className="mt-2 text-sm overflow-hidden relative max-h-[65px] line-clamp-3">
        <Link
          className="clique-oferta-listagem text-sm leading-6 text-gray-700 block"
          href={`/${props?.uri}`}
          target="_self"
          prefetch={shouldDisableNoPrefetch}>
          {props?.titulo}
        </Link>
      </p>
      {props?.produto && (
        <Link
          className="absolute bottom-2 w-[calc(100%_-_32px)] text-gray-600 hover:text-gray-900 hover:underline ease-out duration-300 text-xs font-semibold inline-block no-underline h-[40px] leading-8"
          href={`/${props?.produto?.uri}`}>
          + ofertas desse produto
        </Link>
      )}
    </div>
  );
};

export default function OffersCard({ props }: OffersCardProps) {
  const styleBaseFilter = `saturate-0 ` + `backdrop-filter: grayscale(100%) ` + `opacity-[0.6] `;

  const styleBaseInativo =
    `after:block ` +
    `after:text-[12px] ` +
    `after:leading-[24px] ` +
    `after:rounded-[12px] ` +
    `after:absolute ` +
    `after:top-[94px] ` +
    `after:left-[calc(50%_-_50px)] ` +
    `after:w-[100px] ` +
    `after:h-[24px] ` +
    `after:text-white ` +
    `after:bg-gray-800 ` +
    `after:font-bold ` +
    `after:text-center `;

  const styleBaseEsgotado = styleBaseInativo + " after:content-['ESGOTADA']";
  const styleBaseExpirado = styleBaseInativo + " after:content-['ENCERRADA']";

  let inputProps: { 'data-identifierhighlight'?: string; 'data-identifier'?: string } = {};

  if (props?.highlight == true) {
    inputProps['data-identifierhighlight'] = props?.id?.toString();
  }
  if (props?.highlight == undefined || props?.highlight == false) {
    inputProps['data-identifier'] = props?.id?.toString();
  }

  return (
    <div
      {...inputProps}
      className={`relative flex justify-center items-center  ${props?.status == 4 ? styleBaseEsgotado : ''} ${
        props?.status == 3 || (props?.expiracao && isExpired(props?.expiracao)) ? styleBaseExpirado : ''
      }`}>
      <div
        className={`offer-card relative bg-white rounded max-w-[345px] sm:max-w-full justify-self-center w-full ease-out duration-300 shadow-sm hover:shadow-lg ${
          props?.status == 3 || (props?.expiracao && isExpired(props?.expiracao)) || props?.status == 4 ? styleBaseFilter : ''
        } `}>
        <Image props={props} />
        <DescriptionBody props={props} />
      </div>
    </div>
  );
}
