import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.19_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Google/Ads/AdsSegmentations/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/ButtonGoTop/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/OffersCardList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Refreshing/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/SEOMessage/index.tsx");
